import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "flex flex-row w-full gap-4" }
const _hoisted_2 = { class: "p-4 flex flex-col gap-6" }
const _hoisted_3 = { class: "grid grid-cols-4 divide-x" }
const _hoisted_4 = { class: "grid grid-cols-2 divide-x py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TooltipV2 = _resolveComponent("TooltipV2")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_DetailStatusCardDashboardClient = _resolveComponent("DetailStatusCardDashboardClient")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: _ctx.leftClass }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_TooltipV2, {
            text: _ctx.title,
            fontSize: 14,
            fontWeight: "semibold"
          }, null, 8, ["text"]),
          _createVNode("div", _hoisted_3, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.summaryData, (item, index) => {
              return (_openBlock(), _createBlock(_component_DetailStatusCardDashboardClient, {
                key: item,
                class: 
                `${!index ? '' : 'pl-5'} ${
                  index === _ctx.summaryData.length - 1 ? '' : 'pr-5'
                }`
              ,
                title: item.title,
                tooltipTitle: item.tooltip,
                loading: _ctx.dashboardData.loading,
                value: item.value,
                point: false,
                color: item.color,
                classContainer: "",
                classTitle: "font-normal",
                classValue: "text-20px"
              }, {
                suffix: _withCtx(() => [
                  _createVNode("div", {
                    class: "text-white rounded flex text-10px px-1",
                    style: `background: ${item.color}`
                  }, _toDisplayString(_ctx.formatDecimal(item.percentage.value)) + "% ", 5)
                ]),
                default: _withCtx(() => [
                  index
                    ? (_openBlock(), _createBlock(_component_ProgressBar, {
                        key: 0,
                        value: item.percentage.value,
                        class: "w-full mr-2",
                        startValue: 0,
                        endValue: _ctx.endValue(item.keyEndProgressBar),
                        customColor: item.color
                      }, null, 8, ["value", "endValue", "customColor"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["class", "title", "tooltipTitle", "loading", "value", "color"]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }, 8, ["class"]),
    (_ctx.showSubData)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: ["grid grid-cols-1 gap-3 divide-x", _ctx.rightClass]
        }, [
          (_ctx.hasSlotData)
            ? _renderSlot(_ctx.$slots, "default", { key: 0 })
            : (_openBlock(true), _createBlock(_Fragment, { key: 1 }, _renderList(_ctx.durationData, (key) => {
                return (_openBlock(), _createBlock(_component_Card, { key: key }, {
                  default: _withCtx(() => [
                    _createVNode("div", _hoisted_4, [
                      (_openBlock(true), _createBlock(_Fragment, null, _renderList(key, (item) => {
                        return (_openBlock(), _createBlock(_component_DetailStatusCardDashboardClient, {
                          key: item,
                          class: "px-5",
                          title: item.title,
                          tooltipTitle: item.tooltip,
                          loading: _ctx.dashboardData.loading,
                          value: _ctx.formatDuration(item.value),
                          point: false,
                          color: item.color,
                          classContainer: "",
                          classValue: "text-20px"
                        }, null, 8, ["title", "tooltipTitle", "loading", "value", "color"]))
                      }), 128))
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ]))
}