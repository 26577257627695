import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col p-4" }
const _hoisted_2 = { class: "text-10px lg:text-12px mt-2 font-semibold text-gray-lp-600" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_ColumnChart = _resolveComponent("ColumnChart")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "mr-1" }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_Title, { label: _ctx.title }, null, 8, ["label"]),
        _createVNode("p", _hoisted_2, _toDisplayString(_ctx.currentDate), 1)
      ]),
      _createVNode("div", null, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock("div", _hoisted_3, [
              _createVNode(_component_Skeleton, {
                class: "mt-2",
                height: "1rem"
              }),
              _createVNode(_component_Skeleton, {
                class: "mt-2",
                height: "20rem"
              })
            ]))
          : (
          !_ctx.isLoading && _ctx.summaryData.datasets.length && _ctx.summaryData.labels.length
        )
            ? (_openBlock(), _createBlock(_component_ColumnChart, {
                key: 1,
                seriesData: _ctx.summaryData.datasets,
                categories: _ctx.summaryData.labels,
                colorBar: "#5E9AF8",
                rounded: false,
                barColumnWidth: "40%",
                tooltip: ""
              }, null, 8, ["seriesData", "categories"]))
            : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}