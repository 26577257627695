
import { Options, Vue, prop } from "vue-class-component";
import DetailStatusCardDashboardClient from "@/app/ui/views/dashboard/component/client/component/detail-status-card-dashboard-client.vue";
import { summaryDexData, onMappingDexType } from "./misc";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { ApiRequestList } from "@/domain/entities/MainApp";
import convertMinutesToHoursAndMinutes from "@/app/infrastructures/misc/common-library/ConvertMinutesToHoursAndMinutes";
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { formatPriceWithoutCurrency } from "@/app/infrastructures/misc/Utils";

class Props {
  title = prop<string>({
    default: "",
    type: String
  });
  type = prop<string>({
    default: "all" || "l1" || "l2" || "fake",
    type: String
  });
  leftClass = prop<string>({
    default: "w-3/5",
    type: String
  });
  rightClass = prop<string>({
    default: "w-2/5",
    type: String
  });
  showSubData = prop<boolean>({
    default: true,
    type: Boolean
  });
}
@Options({
  components: {
    DetailStatusCardDashboardClient
  }
})
export default class SectionTotalTicket extends Vue.with(Props) {
  mounted(): void {
    if (this.type !== "fake") {
      this.fetchData();
    }
  }
  formatDecimal(value: number) {
    return formatPriceWithoutCurrency(value);
  }
  endValue(keyEndProgressBar = ""): number {
    if (keyEndProgressBar) {
      return (
        Number(
          this.summaryData.find((e: any) => e.key === keyEndProgressBar)?.value
        ) || 0
      );
    }
    return (
      Number(
        this.summaryData.find((e: any) =>
          e.key.match(/^ticketTotal$|^totalInvalid$|^sttIncoming$/gi)
        )?.value
      ) || 0
    );
  }

  dexData: any = {
    all: onMappingDexType(summaryDexData.slice(0, 8), "all"),
    l1: onMappingDexType(summaryDexData.slice(0, 8), "l1"),
    l2: onMappingDexType(summaryDexData.slice(0, 8), "l2"),
    fake: onMappingDexType(
      [summaryDexData[0], ...summaryDexData.slice(8, 11)],
      "fake"
    ),
    dispute: onMappingDexType(summaryDexData.slice(0, 8), "dispute"),
    "percentage-dispute": onMappingDexType(
      [summaryDexData[0], ...summaryDexData.slice(11, 14)],
      "percentange-dispute"
    ),
    "console-dispute": onMappingDexType(
      summaryDexData.slice(14, 18),
      "console-dispute"
    ),
    "console-stt-dex": onMappingDexType(
      summaryDexData.slice(18, 23),
      "console-stt-dex"
    )
  };

  get filter() {
    return DexAssessmentController.filter;
  }

  dashboardData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchData() {
    const { startDate, endDate } = this.filter;

    try {
      this.dashboardData.loading = true;

      if (this.type === "percentage-dispute") {
        const {
          data: summaryTicket
        } = await DexAssessmentController.getSummaryTicketPercentageDisputeL2(
          new ApiRequestList({
            startDate: formatDateNumber(startDate),
            endDate: formatDateNumber(endDate)
          })
        );
        this.onMappingSummaryTicket(summaryTicket);
      } else if (this.type.match(/^console-dispute$|^console-stt-dex$/gi)) {
        // dashboard for console
        const { data } = await DexAssessmentController.getDashboardConsole(
          new ApiRequestList({
            startDate: formatDateNumber(startDate),
            endDate: formatDateNumber(endDate),
            endpoint:
              this.type === "console-dispute" ? "dex-dispute" : "stt-dex"
          })
        );
        this.onMappingSummaryTicket(data);
      } else {
        const {
          data: summaryTicket
        } = await DexAssessmentController.getSummaryTicket(
          new ApiRequestList({
            startDate: formatDateNumber(startDate),
            endDate: formatDateNumber(endDate),
            ticketType: this.type !== "fake" ? this.type : ""
          })
        );
        this.onMappingSummaryTicket(summaryTicket);
      }
    } catch (error) {
      this.dashboardData.message = parsingErrorResponse(error).type;
    } finally {
      this.dashboardData.loading = false;
    }
  }
  onMappingSummaryTicket(data: any): void {
    const dexData = this.dexData[this.type];
    for (const [key, value] of Object.entries(data)) {
      dexData.forEach((item: any, index: number) => {
        if (item.key === key) {
          this.dexData[this.type][index] = {
            ...dexData[index],
            value: value,
            percentage: {
              ...dexData[index].percentage,
              value: data[item.percentage.key]
            }
          };
        }
      });
    }
  }

  get summaryData() {
    return this.dexData[this.type].slice(0, 4);
  }
  get durationData() {
    const duration = this.dexData[this.type].slice(4, 8);

    const row1 = duration.slice(0, 2);
    const row2 = duration.slice(2, 4);

    return [row1, row2];
  }

  formatDuration(value: number) {
    return convertMinutesToHoursAndMinutes(value);
  }

  // right section
  get hasSlotData() {
    return !!this.$slots.default;
  }
}
