import { SectionTotalTicketData } from "@/domain/entities/DexAssessment";
import { OptionsClass } from "@/domain/entities/MainApp";

export const donutChartDataTotalTicket = [
  {
    key: "ticketDisputeCustomer",
    title: "Total Tiket DEX Disanggah Customer",
    total: new OptionsClass({
      key: "ticketDisputeCustomer"
    }),
    percentage: {
      key: "percentageTicketDisputeCustomer"
    }
  },
  {
    key: "ticketDisputeByPartner",
    title: "Total Tiket DEX Disanggah Consolidator",
    total: new OptionsClass({
      key: "ticketDisputeByPartner"
    }),
    percentage: new OptionsClass({
      key: "percentageTicketDisputeByPartner"
    })
  }
];
export const donutChartData = [
  {
    key: "ticketTotal",
    title: "Persentase Tiket DEX dari Asal DEX",
    dataKey: ["ticketByDriverApp", "ticketByGenesis"],
    data: [0, 0],
    color: ["#4185F7", "#9BC4FA"],
    detail: [
      {
        key: "ticketByDriverApp",
        value: 0,
        color: "#4185F7",
        origin: "Driver Apps",
        percentage: new OptionsClass({
          key: "percentageTicketByDriverApp"
        })
      },
      {
        key: "ticketByGenesis",
        value: 0,
        color: "#9BC4FA",
        origin: "Genesis",
        percentage: new OptionsClass({
          key: "percentageTicketByGenesis"
        })
      }
    ]
  },
  {
    key: "ticketDone",
    title: "Persentase Tiket Selesai dari Status Final Asesmen",
    dataKey: ["ticketValid", "ticketInvalid"],
    data: [0, 0],
    color: ["#7ACB90", "#D4797C"],
    detail: [
      {
        key: "ticketValid",
        value: 0,
        color: "#7ACB90",
        origin: "Tiket DEX Valid",
        percentage: new OptionsClass({
          key: "percentageTicketValid"
        })
      },
      {
        key: "ticketInvalid",
        value: 0,
        color: "#D4797C",
        origin: "Tiket DEX Invalid",
        percentage: new OptionsClass({
          key: "percentageTicketInvalid"
        })
      }
    ]
  },
  ...donutChartDataTotalTicket
];
export const summaryDexData = [
  new SectionTotalTicketData({
    key: "ticketTotal",
    title: "Total Tiket",
    tooltip: "Total Tiket",
    value: "",
    color: ""
  }),
  new SectionTotalTicketData({
    key: "ticketQueue",
    title: "Tiket Antrian",
    tooltip: "Tiket Antrian",
    value: "",
    color: "#58C5E2",
    percentage: new OptionsClass({
      key: "percentageTicketQueue",
      value: ""
    })
  }),
  new SectionTotalTicketData({
    key: "ticketAssigned",
    title: "Tiket Diassign",
    tooltip: "Tiket Diassign",
    value: "",
    color: "#ECB888",
    percentage: new OptionsClass({
      key: "percentageTicketAssigned",
      value: ""
    })
  }),
  new SectionTotalTicketData({
    key: "ticketDone",
    title: "Tiket Selesai",
    tooltip: "Tiket Selesai",
    value: "",
    color: "#7ACB90",
    percentage: new OptionsClass({
      key: "percentageTicketDone",
      value: ""
    })
  }),
  new SectionTotalTicketData({
    key: "averageQueueTime",
    title: "Rata Rata Antrian",
    tooltip: "Rata Rata Antrian",
    value: "",
    color: ""
  }),
  new SectionTotalTicketData({
    key: "maxQueueTime",
    title: "Maksimal Antrian",
    tooltip: "Maksimal Antrian",
    value: "",
    color: ""
  }),
  new SectionTotalTicketData({
    key: "averageDoneTime",
    title: "Rata Rata Selesai",
    tooltip: "Rata Rata Selesai",
    value: "",
    color: ""
  }),
  new SectionTotalTicketData({
    key: "maxDoneTime",
    title: "Maksimal Selesai",
    tooltip: "Maksimal Selesai",
    value: "",
    color: ""
  }),
  new SectionTotalTicketData({
    key: "ticketBySourceDex",
    title: "Dari Asal DEX",
    tooltip: "Dari Asal DEX",
    value: "",
    color: "#4185F7",
    percentage: new OptionsClass({
      key: "percentageTicketBySourceDex",
      value: ""
    })
  }),
  new SectionTotalTicketData({
    key: "ticketByReasonDex",
    title: "Dari Alasan DEX",
    tooltip: "Dari Alasan DEX",
    value: "",
    color: "#8E78DB",
    percentage: new OptionsClass({
      key: "percentageTicketByReasonDex",
      value: ""
    })
  }),
  new SectionTotalTicketData({
    key: "ticketBySourceReasonDex",
    title: "Dari Asal & Alasan DEX",
    tooltip: "Dari Asal & Alasan DEX",
    value: "",
    color: "linear-gradient(90deg, #5E9AF8 1.73%, #8E78DB 96.57%)",
    percentage: new OptionsClass({
      key: "percentageTicketBySourceReasonDex",
      value: ""
    })
  }),
  new SectionTotalTicketData({
    key: "ticketRequested",
    title: "Tiket DEX Pengajuan Sanggah",
    tooltip: "Tiket DEX Pengajuan Sanggah",
    value: "",
    color: "#FFCB01",
    percentage: new OptionsClass({
      key: "percentageTicketDisputeRequested",
      value: ""
    })
  }),
  new SectionTotalTicketData({
    key: "ticketRejected",
    title: "Tiket DEX Sanggah Ditolak",
    tooltip: "Tiket DEX Sanggah Ditolak",
    value: "",
    color: "#D4797C",
    percentage: new OptionsClass({
      key: "percentageTicketDisputeRejected",
      value: ""
    })
  }),
  new SectionTotalTicketData({
    key: "ticketApproved",
    title: "Tiket Sanggah Diterima",
    tooltip: "Tiket Sanggah Diterima",
    value: "",
    color: "#7ACB90",
    percentage: new OptionsClass({
      key: "percentageTicketDisputeApproved",
      value: ""
    })
  }),
  new SectionTotalTicketData({
    key: "totalInvalid",
    title: "Total DEX Invalid",
    tooltip: "Total DEX Invalid",
    value: "",
    color: ""
  }),
  new SectionTotalTicketData({
    key: "totalRequested",
    title: "Total DEX sanggah diajukan",
    tooltip: "Total DEX sanggah diajukan",
    value: "",
    color: "#FFCB01",
    percentage: new OptionsClass({
      key: "percentageRequested",
      value: ""
    })
  }),
  new SectionTotalTicketData({
    key: "totalRejected",
    title: "Total DEX sanggah ditolak",
    tooltip: "Total DEX sanggah ditolak",
    value: "",
    color: "#D4797C",
    percentage: new OptionsClass({
      key: "percentageRejected",
      value: ""
    })
  }),
  new SectionTotalTicketData({
    key: "totalApproved",
    title: "Total DEX sanggah disetujui",
    tooltip: "Total DEX sanggah disetujui",
    value: "",
    color: "#7ACB90",
    percentage: new OptionsClass({
      key: "percentageApproved",
      value: ""
    })
  }),
  new SectionTotalTicketData({
    key: "sttIncoming",
    title: "Total STT incoming",
    tooltip: "Total STT incoming",
    value: "",
    color: ""
  }),
  new SectionTotalTicketData({
    key: "totalDex",
    title: "Total DEX",
    tooltip: "Total DEX",
    value: "",
    color: "#58C5E2",
    percentage: new OptionsClass({
      key: "percentageDex",
      value: ""
    })
  }),
  new SectionTotalTicketData({
    key: "totalDexInvalid",
    title: "Total DEX invalid",
    tooltip: "Total DEX invalid",
    value: "",
    color: "#D4797C",
    percentage: new OptionsClass({
      key: "percentageDexInvalid",
      value: ""
    }),
    keyEndProgressBar: "totalDex"
  }),
  new SectionTotalTicketData({
    key: "totalDexValid",
    title: "Total DEX valid",
    tooltip: "Total DEX valid",
    value: "",
    color: "#7ACB90",
    percentage: new OptionsClass({
      key: "percentageDexValid",
      value: ""
    }),
    keyEndProgressBar: "totalDex"
  })
];

export const onMappingDexType = (data: any[], type: string) =>
  data.map(e => ({ ...e, type }));
