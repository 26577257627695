
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import {
  formatDateNumber,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import ColumnChart from "@/app/ui/components/chart/column-bar/index.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { SummaryTicketDexAssesmentBasedRangeTime } from "@/domain/entities/DexAssessment";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { Options, Vue, prop } from "vue-class-component";

class Props {
  title = prop<string>({
    default: "Total Tiket DEX Berdasarkan Rentang Waktu",
    type: String
  })
}

@Options({
  components: {
    ColumnChart
  }
})
export default class SectionTotalTicketBasedRangeTime extends Vue.with(Props) {
  get currentDate() {
    return `${formatDateWithoutTime(
      this.filter.startDate
    )} - ${formatDateWithoutTime(this.filter.endDate)}`;
  }

  get filter() {
    return DexAssessmentController.filter;
  }

  summaryData = new SummaryTicketDexAssesmentBasedRangeTime();
  isLoading = false;
  async fetchSummaryTicketBasedRangeTime() {
    const partnerId = AccountController.accountData.isConsoleAccount ? AccountController.accountData.account_type_detail.id : "";
    const { startDate, endDate } = this.filter;
    if (startDate && endDate) {
      try {
        this.isLoading = true;
        const payload = new ApiRequestList({
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate),
          reasonDex: this.filter.reasonCode.toString(),
          "3lc": this.filter.city?.code,
          partnerId: partnerId
        });
        const resp = await DexAssessmentController.getSummaryTicketBasedRangeTime(
          payload
        );
        this.summaryData = resp.data;
      } catch (error) {
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            error,
            "Gagal Mendapatkan Total Tiket DEX Berdasarkan Rentang Waktu",
            () => {
              MainAppController.closeErrorMessage();
              this.fetchSummaryTicketBasedRangeTime();
            }
          )
        );
      } finally {
        this.isLoading = false;
      }
    }
  }
}
