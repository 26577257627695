
import { Vue, prop } from "vue-class-component";
import { formatPriceWithoutCurrency } from "@/app/infrastructures/misc/Utils";

class Props {
  data = prop<any>({
    default: null,
    type: Object
  });
  loading = prop<boolean>({
    default: true,
    type: Boolean
  });
}
export default class SectionPercentageDonutChart extends Vue.with(Props) {
  get total() {
    return this.seriesData.reduce((pv: number, cv: number) => pv + cv, 0);
  }
  get seriesData(): number[] {
    return this.data.data;
  }
  formatDecimal(value: number) {
    return formatPriceWithoutCurrency(value);
  }
}
